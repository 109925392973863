@media (max-width: $screen-sm) {
    // sm-view
    .main-slider-block { 
        height: 151vw;
        .main-slider-block-holder { 
            .controlls { 
                bottom: 27px;
            }

            .main-slider { 
                .main-slide { 
                    .main-slide-holder { 

                        .slide-contains { 
                            .slide-contains-holder { 
                                .text {
                                    max-width: 100%;
                                    width: 100%;
                                    h1, .h1 {

                                    }

                                    p {

                                    }
                                }

                                .action {
                                    width: 100%;
                                    .btn {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
