.news-item.open {
    .news-item-holder { 
        a {
            .desc {
                .text {
                    max-height: none;
                    width: 74%;
                    height: auto;
                    margin: 20px 0 0;
                }
            }
        }
    }
}
.news-item { 
    display: inline-block;
    width: 100%;
    height: 300px;
    .news-item-holder { 
        display: inline-block;
        width: 100%;
        height: 100%;
        a { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: white;
            &:hover {
                .desc {
                    .heading { 
                        transition: all 300ms;
                    }

                    .text {
                        max-height: 161px;
                        margin: 20px 0 0;
                        transition: all 300ms;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 6;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            .image { 
                display: inline-block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                background: #09122A;
                .image-holder { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    img { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        opacity: 0.5;
                    }
                }
            }

            .date { 
                position: absolute;
                right: 31px;
                top: 31px;
                z-index: 3;
            }

            .desc { 
                display: flex;
                width: 100%;
                height: 100%;
                flex-direction: column;
                justify-content: flex-end;
                position: relative;
                z-index: 2;
                padding: 0 30px 29px;
                box-sizing: border-box;
                .heading { 
                    display: inline-block;
                    width: 100%;
                    font-weight: 500;
                    transition: all 300ms;
                    strong { 
                        font-weight: bold;
                    }
                }

                .text { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 0;
                    max-height: 0;
                    padding: 0;
                    transition: all 300ms;
                    overflow: hidden;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/news_item";
@import "../../media/mobile/includes/common/news_item";
