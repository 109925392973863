@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .slider-block {
        .slider-block-holder {
            >.heading { 
                margin: 0 0 27px;
                .text { 

                }

                .controlls { 
                    padding: 10px 0 0;
                }
            }

            .action { 
                margin: 25px 0 0;
                a {
                    font-size: 14px;
                    line-height: 15px;
                }
            }
        }
    }
}
