.news-slider-block { 
    display: inline-block;
    width: 100%;
    padding: 80px 0 81px;
	.news-slider-block-holder { 
        display: inline-block;
        width: 100%;
		.news-sldier { 
            display: inline-block;
            width: 100%;
			.news-sldier-holder { 

			}
		}
	}
}

@import "../../media/tablet/includes/common/news_slider";
@import "../../media/mobile/includes/common/news_slider";

