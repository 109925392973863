.slider-block {
    .slider-block-holder {
        >.heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 35px;
			.text { 
                float: left;
                max-width: 50%;
				>* { 
                    margin: 0;
				}
			}

			.controlls { 
                float: right;
                width: 50%;
                display: flex;
                justify-content: flex-end;
                padding: 14px 0 0;
				.prev, .next { 

					svg { 

						path { 

						}
					}
				}

				.nav { 
                    ul.slick-dots {
                        margin: 0 26px;
                    }
				}
			}
		}

        .action { 
            display: inline-block;
            width: 100%;
            margin: 37px 0 0;
			a { 
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: $brand-primary;
                text-decoration: underline;
                text-decoration-style: dotted;
                text-decoration-color: currentColor;
                &:hover {
                    text-decoration-color: transparent;
                }
			}
		}
    }
}

@import "../../media/tablet/includes/common/slider_block";
@import "../../media/mobile/includes/common/slider_block";
