@media (max-width: $screen-lg) {
    // lg-md view
    .news-item { 
        height: 232px;
        .news-item-holder { 
            a {
                &:hover {
                    .desc {
                        .text {
                            max-height: 90px;
                            display: -webkit-box;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .desc { 
                    padding: 0 15px 15px;
                    .heading {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 20px;
                    }

                    .text {
                       
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .news-item { 
        height: 29vw;
        .news-item-holder { 
            a {
                font-size: 14px;
                line-height: 15px;
                .date { 
                    right: 17px;
                    top: 16px;
                }
                
                .desc { 
                    padding: 0 15px 15px;
                    .heading {
                        font-size: 16px;
                        line-height: 18px;
                    }

                    .text {
                       
                    }
                }
            }
        }
    }
}
