.main-slider-block { 
    display: inline-block;
    width: 100%;
    height: 624px;
	.main-slider-block-holder { 
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
		.controlls { 
            display: inline-block;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 38px;
            z-index: 2;
			.controlls-holder { 
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
				.prev, .next { 
                    position: relative;
                    left: auto;
                    right: auto;
                    top: auto;
                    transform: none;
                    float: left;
                    color: white;
                    &:hover {
                        color: $brand-secondary;
                    }
                    &.slick-disabled {
                        color: $gray;
                        opacity: 0.5;
                        &:hover {
                            color: $gray;
                        }
                    }
					svg { 

						path { 

						}
					}
				}

				.nav { 
                    float: left;
                    ul.slick-dots {
                        position: relative;
                        left: auto;
                        bottom: auto;
                        transform: none;
                        li {
                            &.slick-active {
                                button {
                                    background: white;
                                }
                            }
                            button {
                                background: transparent;
                                border-color: white;
                                &:hover {
                                    background: white;
                                }
                            }
                        }
                    }
				}

			}
		}

		.main-slider { 
            display: inline-block;
            width: 100%;
            height: 100%;
			.main-slide { 
                display: inline-block;
                width: 100%;
                height: 100%;
                position: relative;
				.main-slide-holder { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
					.slide-image { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
						.slide-image-holder { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            background: $brand-primary;
                            position: relative;
                            &:after {
                                content: "";
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 2;
                                background: $brand-primary;
                                opacity: 0.3;
                            }
							img { 
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                opacity: 0.6;
                                position: relative;
                                z-index: 1;
							}
						}
					}

					.slide-contains { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        position: relative;
                        z-index: 2;
                        .wrapper-inside {
                            height: 100%;
                        }

						.slide-contains-holder { 
                            display: flex;
                            width: 100%;
                            height: 100%;
                            flex-direction: column;
                            justify-content: center;
                            .text { 
                                max-width: 648px;
                                color: white;
                                margin:  0 0 40px;
                                h1, .h1 { 
                                    margin: 0 0 15px;
                                }

                                p { 

                                }
                            }

                            .action { 

                                .btn { 
                                    min-width: 286px;
                                }
                            }
                        }
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/main_slider";
@import "../../media/mobile/includes/index/main_slider";
