@media (max-width: $screen-sm) {
    // sm-view
    .services-block { 
        padding: 17px 0 1px;
        .services-block-holder { 
            .services-list { 
                width: 100%;
                margin: 0;
                .service { 
                    width: 100%;
                    padding: 0;
                    .service-holder { 
                        height: 90px;
                        a {
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            line-height: 13px;
                            padding: 15px;
                            .icon {
                                width: 60px;
                                height: 60px;
                                margin: 0;
                                padding: 0;
                                border: none;
                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .text {
                                width: calc(100% - 60px);
                                box-sizing: border-box;
                                padding-left: 15px;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
