@media (max-width: $screen-lg) {
    // lg-md view
    .question-form {
        padding: 50px 0 46px;
        .question-form-holder { 
            .desc { 
                max-width: 45%;
            }

            .form-container { 
                width: 53.7%;
                padding: 0;
                margin: -9px 0 0;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .question-form { 
        padding: 40px 0 33px;
        .question-form-holder { 
            .desc {

            }

            .form-container { 
                width: 48.6%;
                .form-container-holder { 

                    form {
                        .form-group {
                            .form-col {
                                &.col-50 {
                                    width: 100%;
                                }
                            }
                        }

                        .action {
                            margin: 6px 0 0;
                        }
                    }
                }
            }
        }
    }
}
