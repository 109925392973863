@media (max-width: $screen-sm) {
    // sm-view
    .news-item { 
        height: 63vw;
        .news-item-holder { 
            a {
                font-size: 12px;
                line-height: 13px;
                .date { 
                    right: 17px;
                    top: 16px;
                }
                
                .desc { 
                    padding: 0 15px 15px;
                    .heading {
                        font-size: 14px;
                        line-height: 15px;
                    }

                    .text {
                       
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
