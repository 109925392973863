@media (max-width: $screen-sm) {
    // sm-view
    .question-form { 
        padding: 40px 0 42px;
        .question-form-holder { 
            .desc {
                width: 100%;
                min-width: auto;
                max-width: none;
                margin: 0 0 53px;
            }

            .form-container { 
                width: 100%;
                margin: 0;
                padding: 0;
                .form-container-holder { 
                    form { 
                        .action {
                            margin: 5px 0 0;

                            .form-field {
                                margin: 0 0 20px;
                                label.checkcontainer {
                                    display: flex;
                                    align-items: center;
                                    .name {
                                        font-size: 12px;
                                        line-height: 18px;
                                        height: 36px;
                                    }
                                }
                            }

                            .submit {
                                width: 100%;
                                .btn {
                                    padding: 12.5px 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
