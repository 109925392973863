@media (max-width: $screen-lg) {
    // lg-md view
    .services-block { 
        .services-block-holder { 
            .services-list { 
                .service { 
                    .service-holder { 
                        height: 257px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .services-block { 
        padding: 26px 0 11px;
        .services-block-holder { 
            .services-list { 
                .service { 
                    .service-holder { 
                        height: 214px;
                        a {
                            padding: 9px 0 0;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 15px;
                            .icon {
                                width: 120px;
                                height: 95px;
                                padding: 0 0 20px;
                                margin: 0 0 20px;
                                svg {
                                    width: 95x;
                                    height: 95px;
                                }
                            }

                            .text {

                            }
                        }
                    }
                }
            }
        }
    }
}
