.services-block { 
    display: inline-block;
    width: 100%;
    padding: 60px 0 45px;
    margin: -3px 0 0;
	.services-block-holder { 
        display: inline-block;
        width: 100%;
        .services-list { 
            display: inline-block;
            width: calc(100% + 10px);
            margin-left: -5px;
            .service { 
                float: left;
                width: 20%;
                box-sizing: border-box;
                padding: 0 5px;
                margin: 0 0 10px;
                .service-holder { 
                    display: inline-block;
                    width: 100%;
                    height: 270px;
                    a { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        background: $brand-primary;
                        text-align: center;
                        color: white;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 18px;
                        padding: 32px 0 0;
                        box-sizing: border-box;
                        position: relative;
                        &:hover {
                            &:after {
                                height: 10px;
                                transition: all 300ms;
                            }
                        }
                        &:after {
                            content: "";
                            display: inline-block;
                            width: 100%;
                            height: 0px;
                            background: $brand-secondary;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            transition: all 300ms;
                        }
                        .icon { 
                            display: inline-block;
                            width: 120px;
                            height: 120px;
                            text-align: center;
                            padding: 0 0 18px;
                            border-bottom: 1px solid currentColor;
                            margin: 0 0 20px;
                            svg { 
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .text { 
                            display: inline-block;
                            width: 100%;
                            text-align: center;
                            br { 

                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/services";
@import "../../media/mobile/includes/index/services";
