.company-numbers-block {
  display: inline-block;
  width: 100%;
  padding: 0 0 91px;
}
.company-numbers-block.standalone {
  padding: 97px 0 91px;
}
.company-numbers-block .company-numbers-block-holder {
  display: inline-block;
  width: 100%;
}
.company-numbers-block .company-numbers-block-holder .numbers {
  display: inline-block;
  width: calc(100% + 10px);
  margin-left: -5px;
}
.company-numbers-block .company-numbers-block-holder .numbers .number {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
  margin: 0 0 10px;
}
.company-numbers-block .company-numbers-block-holder .numbers .number.col-25 {
  width: 25%;
}
.company-numbers-block .company-numbers-block-holder .numbers .number.col-50 {
  width: 50%;
}
.company-numbers-block .company-numbers-block-holder .numbers .number.col-33 {
  width: 33.333%;
}
.company-numbers-block .company-numbers-block-holder .numbers .number.col-66 {
  width: 66.666%;
}
.company-numbers-block .company-numbers-block-holder .numbers .number .number-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px 30px 0;
  width: 100%;
  height: 190px;
  overflow: hidden;
  background: #F3F3F3;
  color: #09388A;
  transition: all 300ms;
}
.company-numbers-block .company-numbers-block-holder .numbers .number .number-holder:hover {
  color: #FE991F;
  transition: all 300ms;
}
.company-numbers-block .company-numbers-block-holder .numbers .number .number-holder .heading {
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 66px;
  margin: 0 0 10px;
}
.company-numbers-block .company-numbers-block-holder .numbers .number .number-holder .heading small {
  transform: translateY(-20px);
  display: inline-block;
  font-weight: 400;
  font-size: 63%;
  line-height: 0;
}
.company-numbers-block .company-numbers-block-holder .numbers .number .number-holder .text {
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

@media (max-width: 1609px) {
  .company-numbers-block {
    padding: 0 0 68px;
  }
  .company-numbers-block.standalone {
    padding: 81px 0 68px;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number.col-lg-25 {
    width: 25%;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number.col-lg-50 {
    width: 50%;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number.col-lg-33 {
    width: 33.333%;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number.col-lg-66 {
    width: 66.666%;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number .number-holder {
    height: 180px;
    padding: 2px 30px 0;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number .number-holder .heading {
    margin: 0 0 5px;
  }
}
@media (max-width: 1279px) {
  .company-numbers-block {
    padding: 0 0 47px;
  }
  .company-numbers-block.standalone {
    padding: 59px 0 47px;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number.col-md-25 {
    width: 25%;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number.col-md-50 {
    width: 50%;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number.col-md-33 {
    width: 33.333%;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number.col-md-66 {
    width: 66.666%;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number .number-holder {
    height: 130px;
    padding: 6px 10px 0;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number .number-holder:focus {
    color: #FE991F;
    transition: all 300ms;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number .number-holder .heading {
    margin: 0 0 8px;
    font-size: 38px;
    line-height: 42px;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number .number-holder .heading small {
    transform: translateY(-11px);
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number .number-holder .text {
    font-size: 16px;
    line-height: 140%;
  }
}
@media (max-width: 767px) {
  .company-numbers-block {
    overflow: hidden;
    padding: 0 0 82px;
  }
  .company-numbers-block.standalone {
    padding: 41px 0 34px;
  }
  .company-numbers-block .company-numbers-block-holder .slick-slider .slick-list {
    overflow: visible;
  }
  .company-numbers-block .company-numbers-block-holder .slick-slider .slick-list .slick-track .slick-slide {
    padding: 0 5px;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number .number-holder {
    width: 230px;
    height: 141px;
    padding: 0 16px 0;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number .number-holder .heading {
    font-size: 28px;
    line-height: 31px;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number .number-holder .heading small {
    transform: translateY(-9px);
    font-size: 59%;
  }
  .company-numbers-block .company-numbers-block-holder .numbers .number .number-holder .text {
    font-size: 14px;
    line-height: 140%;
  }
}
.main-slider-block {
  display: inline-block;
  width: 100%;
  height: 624px;
}
.main-slider-block .main-slider-block-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.main-slider-block .main-slider-block-holder .controlls {
  display: inline-block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 38px;
  z-index: 2;
}
.main-slider-block .main-slider-block-holder .controlls .controlls-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.main-slider-block .main-slider-block-holder .controlls .controlls-holder .prev, .main-slider-block .main-slider-block-holder .controlls .controlls-holder .next {
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  transform: none;
  float: left;
  color: white;
}
.main-slider-block .main-slider-block-holder .controlls .controlls-holder .prev:hover, .main-slider-block .main-slider-block-holder .controlls .controlls-holder .next:hover {
  color: #FE991F;
}
.main-slider-block .main-slider-block-holder .controlls .controlls-holder .prev.slick-disabled, .main-slider-block .main-slider-block-holder .controlls .controlls-holder .next.slick-disabled {
  color: #F3F3F3;
  opacity: 0.5;
}
.main-slider-block .main-slider-block-holder .controlls .controlls-holder .prev.slick-disabled:hover, .main-slider-block .main-slider-block-holder .controlls .controlls-holder .next.slick-disabled:hover {
  color: #F3F3F3;
}
.main-slider-block .main-slider-block-holder .controlls .controlls-holder .nav {
  float: left;
}
.main-slider-block .main-slider-block-holder .controlls .controlls-holder .nav ul.slick-dots {
  position: relative;
  left: auto;
  bottom: auto;
  transform: none;
}
.main-slider-block .main-slider-block-holder .controlls .controlls-holder .nav ul.slick-dots li.slick-active button {
  background: white;
}
.main-slider-block .main-slider-block-holder .controlls .controlls-holder .nav ul.slick-dots li button {
  background: transparent;
  border-color: white;
}
.main-slider-block .main-slider-block-holder .controlls .controlls-holder .nav ul.slick-dots li button:hover {
  background: white;
}
.main-slider-block .main-slider-block-holder .main-slider {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-image {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-image .slide-image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #09388A;
  position: relative;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-image .slide-image-holder:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  background: #09388A;
  opacity: 0.3;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-image .slide-image-holder img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
  position: relative;
  z-index: 1;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .wrapper-inside {
  height: 100%;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder .text {
  max-width: 648px;
  color: white;
  margin: 0 0 40px;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder .text h1, .main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder .text .h1 {
  margin: 0 0 15px;
}
.main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder .action .btn {
  min-width: 286px;
}

@media (max-width: 1279px) {
  .main-slider-block {
    height: 65vw;
  }
  .main-slider-block .main-slider-block-holder .controlls {
    bottom: 26px;
  }
  .main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder .text {
    margin: 0 0 30px;
  }
  .main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder .text h1, .main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder .text .h1 {
    margin: 0 0 15px;
  }
  .main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder .action .btn {
    min-width: 227px;
  }
}
@media (max-width: 767px) {
  .main-slider-block {
    height: 151vw;
  }
  .main-slider-block .main-slider-block-holder .controlls {
    bottom: 27px;
  }
  .main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder .text {
    max-width: 100%;
    width: 100%;
  }
  .main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder .action {
    width: 100%;
  }
  .main-slider-block .main-slider-block-holder .main-slider .main-slide .main-slide-holder .slide-contains .slide-contains-holder .action .btn {
    width: 100%;
  }
}
.question-form {
  display: inline-block;
  width: 100%;
  background: #09388A;
  box-sizing: border-box;
  padding: 57px 0 56px;
}
.question-form .question-form-holder {
  display: inline-block;
  width: 100%;
}
.question-form .question-form-holder .desc {
  float: left;
  min-width: 28%;
  max-width: 50%;
}
.question-form .question-form-holder .desc .desc-holder {
  display: inline-block;
  width: 100%;
  color: white;
}
.question-form .question-form-holder .desc .desc-holder h1, .question-form .question-form-holder .desc .desc-holder .h1 {
  margin: 0 0 10px;
}
.question-form .question-form-holder .desc .desc-holder p {
  color: #95A7B9;
}
.question-form .question-form-holder .desc .desc-holder p:not(:last-of-type) {
  margin: 0 0 10px;
}
.question-form .question-form-holder .form-container {
  float: right;
  width: 49%;
  box-sizing: border-box;
  padding-right: 8.5%;
  margin: -8px 0 0;
}
.question-form .question-form-holder .form-container .form-container-holder form .action {
  margin: -6px 0 0;
}
.question-form .question-form-holder .form-container .form-container-holder form .action .submit .btn.btn-outline {
  background: transparent;
  border-color: white;
  color: white;
}
.question-form .question-form-holder .form-container .form-container-holder form .action .submit .btn.btn-outline:hover {
  background: white;
  color: #09388A;
}
.question-form .question-form-holder .form-container .form-container-holder form .action .note {
  width: 100%;
  margin: 21px 0 0;
}

@media (max-width: 1609px) {
  .question-form {
    padding: 50px 0 46px;
  }
  .question-form .question-form-holder .desc {
    max-width: 45%;
  }
  .question-form .question-form-holder .form-container {
    width: 53.7%;
    padding: 0;
    margin: -9px 0 0;
  }
}
@media (max-width: 1279px) {
  .question-form {
    padding: 40px 0 33px;
  }
  .question-form .question-form-holder .form-container {
    width: 48.6%;
  }
  .question-form .question-form-holder .form-container .form-container-holder form .form-group .form-col.col-50 {
    width: 100%;
  }
  .question-form .question-form-holder .form-container .form-container-holder form .action {
    margin: 6px 0 0;
  }
}
@media (max-width: 767px) {
  .question-form {
    padding: 40px 0 42px;
  }
  .question-form .question-form-holder .desc {
    width: 100%;
    min-width: auto;
    max-width: none;
    margin: 0 0 53px;
  }
  .question-form .question-form-holder .form-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .question-form .question-form-holder .form-container .form-container-holder form .action {
    margin: 5px 0 0;
  }
  .question-form .question-form-holder .form-container .form-container-holder form .action .form-field {
    margin: 0 0 20px;
  }
  .question-form .question-form-holder .form-container .form-container-holder form .action .form-field label.checkcontainer {
    display: flex;
    align-items: center;
  }
  .question-form .question-form-holder .form-container .form-container-holder form .action .form-field label.checkcontainer .name {
    font-size: 12px;
    line-height: 18px;
    height: 36px;
  }
  .question-form .question-form-holder .form-container .form-container-holder form .action .submit {
    width: 100%;
  }
  .question-form .question-form-holder .form-container .form-container-holder form .action .submit .btn {
    padding: 12.5px 10px;
  }
}
.services-block {
  display: inline-block;
  width: 100%;
  padding: 60px 0 45px;
  margin: -3px 0 0;
}
.services-block .services-block-holder {
  display: inline-block;
  width: 100%;
}
.services-block .services-block-holder .services-list {
  display: inline-block;
  width: calc(100% + 10px);
  margin-left: -5px;
}
.services-block .services-block-holder .services-list .service {
  float: left;
  width: 20%;
  box-sizing: border-box;
  padding: 0 5px;
  margin: 0 0 10px;
}
.services-block .services-block-holder .services-list .service .service-holder {
  display: inline-block;
  width: 100%;
  height: 270px;
}
.services-block .services-block-holder .services-list .service .service-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #09388A;
  text-align: center;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 32px 0 0;
  box-sizing: border-box;
  position: relative;
}
.services-block .services-block-holder .services-list .service .service-holder a:hover:after {
  height: 10px;
  transition: all 300ms;
}
.services-block .services-block-holder .services-list .service .service-holder a:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 0px;
  background: #FE991F;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 300ms;
}
.services-block .services-block-holder .services-list .service .service-holder a .icon {
  display: inline-block;
  width: 120px;
  height: 120px;
  text-align: center;
  padding: 0 0 18px;
  border-bottom: 1px solid currentColor;
  margin: 0 0 20px;
}
.services-block .services-block-holder .services-list .service .service-holder a .icon svg {
  width: 100%;
  height: 100%;
}
.services-block .services-block-holder .services-list .service .service-holder a .text {
  display: inline-block;
  width: 100%;
  text-align: center;
}
@media (max-width: 1609px) {
  .services-block .services-block-holder .services-list .service .service-holder {
    height: 257px;
  }
}
@media (max-width: 1279px) {
  .services-block {
    padding: 26px 0 11px;
  }
  .services-block .services-block-holder .services-list .service .service-holder {
    height: 214px;
  }
  .services-block .services-block-holder .services-list .service .service-holder a {
    padding: 9px 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }
  .services-block .services-block-holder .services-list .service .service-holder a .icon {
    width: 120px;
    height: 95px;
    padding: 0 0 20px;
    margin: 0 0 20px;
  }
  .services-block .services-block-holder .services-list .service .service-holder a .icon svg {
    width: 95x;
    height: 95px;
  }
}
@media (max-width: 767px) {
  .services-block {
    padding: 17px 0 1px;
  }
  .services-block .services-block-holder .services-list {
    width: 100%;
    margin: 0;
  }
  .services-block .services-block-holder .services-list .service {
    width: 100%;
    padding: 0;
  }
  .services-block .services-block-holder .services-list .service .service-holder {
    height: 90px;
  }
  .services-block .services-block-holder .services-list .service .service-holder a {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 13px;
    padding: 15px;
  }
  .services-block .services-block-holder .services-list .service .service-holder a .icon {
    width: 60px;
    height: 60px;
    margin: 0;
    padding: 0;
    border: none;
  }
  .services-block .services-block-holder .services-list .service .service-holder a .icon svg {
    width: 100%;
    height: 100%;
  }
  .services-block .services-block-holder .services-list .service .service-holder a .text {
    width: calc(100% - 60px);
    box-sizing: border-box;
    padding-left: 15px;
    text-align: left;
  }
}
.news-item.open .news-item-holder a .desc .text {
  max-height: none;
  width: 74%;
  height: auto;
  margin: 20px 0 0;
}

.news-item {
  display: inline-block;
  width: 100%;
  height: 300px;
}
.news-item .news-item-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.news-item .news-item-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: white;
}
.news-item .news-item-holder a:hover .desc .heading {
  transition: all 300ms;
}
.news-item .news-item-holder a:hover .desc .text {
  max-height: 161px;
  margin: 20px 0 0;
  transition: all 300ms;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.news-item .news-item-holder a .image {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: #09122A;
}
.news-item .news-item-holder a .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.news-item .news-item-holder a .image .image-holder img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}
.news-item .news-item-holder a .date {
  position: absolute;
  right: 31px;
  top: 31px;
  z-index: 3;
}
.news-item .news-item-holder a .desc {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  padding: 0 30px 29px;
  box-sizing: border-box;
}
.news-item .news-item-holder a .desc .heading {
  display: inline-block;
  width: 100%;
  font-weight: 500;
  transition: all 300ms;
}
.news-item .news-item-holder a .desc .heading strong {
  font-weight: bold;
}
.news-item .news-item-holder a .desc .text {
  display: inline-block;
  width: 100%;
  margin: 0 0 0;
  max-height: 0;
  padding: 0;
  transition: all 300ms;
  overflow: hidden;
}

@media (max-width: 1609px) {
  .news-item {
    height: 232px;
  }
  .news-item .news-item-holder a:hover .desc .text {
    max-height: 90px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .news-item .news-item-holder a .desc {
    padding: 0 15px 15px;
  }
  .news-item .news-item-holder a .desc .heading {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }
}
@media (max-width: 1279px) {
  .news-item {
    height: 29vw;
  }
  .news-item .news-item-holder a {
    font-size: 14px;
    line-height: 15px;
  }
  .news-item .news-item-holder a .date {
    right: 17px;
    top: 16px;
  }
  .news-item .news-item-holder a .desc {
    padding: 0 15px 15px;
  }
  .news-item .news-item-holder a .desc .heading {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 767px) {
  .news-item {
    height: 63vw;
  }
  .news-item .news-item-holder a {
    font-size: 12px;
    line-height: 13px;
  }
  .news-item .news-item-holder a .date {
    right: 17px;
    top: 16px;
  }
  .news-item .news-item-holder a .desc {
    padding: 0 15px 15px;
  }
  .news-item .news-item-holder a .desc .heading {
    font-size: 14px;
    line-height: 15px;
  }
}
.news-slider-block {
  display: inline-block;
  width: 100%;
  padding: 80px 0 81px;
}
.news-slider-block .news-slider-block-holder {
  display: inline-block;
  width: 100%;
}
.news-slider-block .news-slider-block-holder .news-sldier {
  display: inline-block;
  width: 100%;
}
@media (max-width: 1609px) {
  .news-slider-block {
    padding: 62px 0 59px;
  }
}
@media (max-width: 1279px) {
  .news-slider-block {
    padding: 39px 0 38px;
  }
}
.slider-block .slider-block-holder > .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 35px;
}
.slider-block .slider-block-holder > .heading .text {
  float: left;
  max-width: 50%;
}
.slider-block .slider-block-holder > .heading .text > * {
  margin: 0;
}
.slider-block .slider-block-holder > .heading .controlls {
  float: right;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  padding: 14px 0 0;
}
.slider-block .slider-block-holder > .heading .controlls .nav ul.slick-dots {
  margin: 0 26px;
}
.slider-block .slider-block-holder .action {
  display: inline-block;
  width: 100%;
  margin: 37px 0 0;
}
.slider-block .slider-block-holder .action a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #09388A;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: currentColor;
}
.slider-block .slider-block-holder .action a:hover {
  text-decoration-color: transparent;
}

@media (max-width: 1279px) {
  .slider-block .slider-block-holder > .heading {
    margin: 0 0 27px;
  }
  .slider-block .slider-block-holder > .heading .controlls {
    padding: 10px 0 0;
  }
  .slider-block .slider-block-holder .action {
    margin: 25px 0 0;
  }
  .slider-block .slider-block-holder .action a {
    font-size: 14px;
    line-height: 15px;
  }
}
@media (max-width: 767px) {
  .slider-block .slider-block-holder {
    position: relative;
  }
  .slider-block .slider-block-holder > .heading {
    margin: 0 0 27px;
  }
  .slider-block .slider-block-holder > .heading .text {
    max-width: 75%;
  }
  .slider-block .slider-block-holder > .heading .controlls {
    width: 25%;
    margin-right: -14.5px;
    padding: 7px 0 0;
  }
  .slider-block .slider-block-holder > .heading .controlls .prev, .slider-block .slider-block-holder > .heading .controlls .next {
    margin: 0 12.5px;
  }
  .slider-block .slider-block-holder > .heading .controlls .nav {
    position: absolute;
    bottom: 2px;
    right: -2px;
  }
  .slider-block .slider-block-holder > .heading .controlls .nav ul.slick-dots {
    margin: 0;
  }
  .slider-block .slider-block-holder > .heading .controlls .nav ul.slick-dots li button {
    width: 25px;
  }
  .slider-block .slider-block-holder .action {
    margin: 25px 0 0;
  }
  .slider-block .slider-block-holder .action a {
    font-size: 12px;
    line-height: 13px;
  }
}