@media (max-width: $screen-lg) {
    // lg-md view
    .company-numbers-block { 
        padding: 0 0 68px;
        &.standalone {
            padding: 81px 0 68px;
        }
        .company-numbers-block-holder { 
            .numbers { 
                .number { 
                    &.col-lg-25 {
                        width: 25%;
                    }
                    &.col-lg-50 {
                        width: 50%;
                    }
                    &.col-lg-33 {
                        width: 33.333%;
                    }
                    &.col-lg-66 {
                        width: 66.666%;
                    }
                    .number-holder { 
                        height: 180px;
                        padding: 2px 30px 0;
                        .heading { 
                            margin: 0 0 5px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .company-numbers-block { 
        padding: 0 0 47px;
        &.standalone {
            padding: 59px 0 47px;
        }
        .company-numbers-block-holder { 
            .numbers { 
                .number { 
                    &.col-md-25 {
                        width: 25%;
                    }
                    &.col-md-50 {
                        width: 50%;
                    }
                    &.col-md-33 {
                        width: 33.333%;
                    }
                    &.col-md-66 {
                        width: 66.666%;
                    }
                    .number-holder { 
                        height: 130px;
                        padding: 6px 10px 0;
                        &:focus {
                            color: $brand-secondary;
                            transition: all 300ms;
                        }
                        .heading { 
                            margin: 0 0 8px;
                            font-size: 38px;
                            line-height: 42px;
                            small {
                                transform: translateY(-11px);
                            }
                        }

                        .text { 
                            font-size: 16px;
                            line-height: 140%;
                        }
                    }
                }
            }
        }
    }
}
