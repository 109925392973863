@media (max-width: $screen-sm) {
    // sm-view
    .slider-block {
        .slider-block-holder {
            position: relative;
            >.heading { 
                margin: 0 0 27px;
                .text { 
                    max-width: 75%;
                }

                .controlls { 
                    width: 25%;
                    margin-right: -14.5px;
                    padding: 7px 0 0;
                    .prev, .next { 
                        margin: 0 12.5px;
                    }
                    .nav { 
                        position: absolute;
                        bottom: 2px;
                        right: -2px;
                        ul.slick-dots {
                            margin: 0;
                            li {
                                button {
                                    width: 25px;
                                }
                            }
                        }
                    }
                }
            }

            .action { 
                margin: 25px 0 0;
                a {
                    font-size: 12px;
                    line-height: 13px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
