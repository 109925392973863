.company-numbers-block { 
    display: inline-block;
    width: 100%;
    padding: 0 0 91px;
    &.standalone {
        padding: 97px 0 91px;
    }
	.company-numbers-block-holder { 
        display: inline-block;
        width: 100%;
		.numbers { 
            display: inline-block;
            width: calc(100% + 10px);
            margin-left: -5px;
			.number { 
                float: left;
                width: 100%;
                box-sizing: border-box;
                padding: 0 5px;
                margin: 0 0 10px;
                &.col-25 {
                    width: 25%;
                }
                &.col-50 {
                    width: 50%;
                }
                &.col-33 {
                    width: 33.333%;
                }
                &.col-66 {
                    width: 66.666%;
                }
				.number-holder { 
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    box-sizing: border-box;
                    padding: 12px 30px 0;
                    width: 100%;
                    height: 190px;
                    overflow: hidden;
                    background: $bg-secondary;
                    color: $brand-primary;
                    transition: all 300ms;
                    &:hover {
                        color: $brand-secondary;
                        transition: all 300ms;
                    }
					.heading { 
                        display: inline-block;
                        width: 100%;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 60px;
                        line-height: 66px;
                        margin: 0 0 10px;
						small { 
                            transform: translateY(-20px);
                            display: inline-block;
                            font-weight: 400;
                            font-size: 63%;
                            line-height: 0;
						}
					}

					.text { 
                        display: inline-block;
                        width: 100%;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 140%;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/company_numbers";
@import "../../media/mobile/includes/index/company_numbers";
