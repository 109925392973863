@media (max-width: $screen-sm) {
    // sm-view
    .company-numbers-block { 
        overflow: hidden;
        padding: 0 0 82px;
        &.standalone {
            padding: 41px 0 34px;
        }
        .company-numbers-block-holder { 

            .slick-slider {
                .slick-list {
                    overflow: visible;
                    .slick-track {
                        .slick-slide {
                            padding: 0 5px;
                        }
                    }
                }
            }

            .numbers { 
                .number {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    .number-holder {
                        width: 230px;
                        height: 141px;
                        padding: 0 16px 0;
                        .heading { 
                            font-size: 28px;
                            line-height: 31px;
                            small {
                                transform: translateY(-9px);
                                font-size: 59%;
                            }
                        }

                        .text {
                            font-size: 14px;
                            line-height: 140%;
                        }
                    }
                }
            }


        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
