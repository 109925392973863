.question-form { 
    display: inline-block;
    width: 100%;
    background: $brand-primary;
    box-sizing: border-box;
    padding: 57px 0 56px;
	.question-form-holder { 
        display: inline-block;
        width: 100%;
		.desc { 
            float: left;
            min-width: 28%;
            max-width: 50%;
			.desc-holder { 
                display: inline-block;
                width: 100%;
                color: white;
				h1, .h1 { 
                    margin: 0 0 10px;
				}

				p { 
                    color: #95A7B9;
                    &:not(:last-of-type) {
                        margin: 0 0 10px;
                    }
				}
			}
		}

		.form-container { 
            float: right;
            width: 49%;
            box-sizing: border-box;
            padding-right: 8.5%;
            margin: -8px 0 0;
			.form-container-holder { 

				form { 
                    .action {
                        margin: -6px 0 0;

                        .submit {
                            .btn.btn-outline {
                                background: transparent;
                                border-color: white;
                                color: white;
                                &:hover {
                                    background: white;
                                    color: $brand-primary;
                                }
                            }
                        }

                        .note {
                            width: 100%;
                            margin: 21px 0 0;
                        }
                    }
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/question_form";
@import "../../media/mobile/includes/index/question_form";
