@media (max-width: $screen-lg) {
    // lg-md view
    .news-slider-block { 
        padding: 62px 0 59px;
    }
}

@media (max-width: $screen-md) {
    // md-view
    .news-slider-block { 
        padding: 39px 0 38px;
    }
}
