@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .main-slider-block { 
        height: 65vw;
        .main-slider-block-holder { 

            .controlls { 
                bottom: 26px;
            }

            .main-slider { 
                .main-slide { 
                    .main-slide-holder { 
                        .slide-contains { 
                            .slide-contains-holder { 
                                .text { 
                                    margin:  0 0 30px;
                                    h1, .h1 { 
                                        margin: 0 0 15px;
                                    }

                                    p {

                                    }
                                }

                                .action {
                                    .btn {
                                        min-width: 227px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
